import http from '../http/request'
// 获取验证码
export async function getImageApi() {
    // return await http.get('/api/captcha', null)
    return await http.get('/user/code', null)

}
// 获取用户列表
export async function getUserListApi(params) {
    return http.get('/user/list', params)
}
// 用户添加
export async function addUserApi(params) {
    return http.post('/user/list', params)
}
// 用户编辑
export async function editUserApi(params) {
    return http.put('/user/list/' + params.id, params)
}
// 用户删除
export async function deleteUserApi(params) {
    return http.delete('/user/list/' + params.id, null)
}
// 获取角色列表
export async function getRolistForAssignApi(params) {
    // return http.get('api/user/getRolistForAssignApi', params)
    return http.get('/role/list', params)
}
//获取用户角色
export async function getRoleIdByUserIdApi(params) {
    return http.get('/user/getRoleIdByUserId/' + params.userId, null)
}
//分配用户角色
export async function assignSaveApi(params) {
    // return http.post('/user/assignSaveApi', params)
    return http.post('/user/getRoleIdByUserId', params)
}